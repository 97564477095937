import { z } from 'zod';
import type { Prisma } from '@prisma/client';
import { JobSchema } from '../prisma/job';
import { TaskStateSchema } from '../prisma/task';

export const UpdateJobSchema = JobSchema.omit({
	id: true,
	ownerId: true,
	updatedAt: true,
	createdAt: true,
	deletedAt: true,
}) satisfies z.ZodType<Prisma.JobUpdateInput>;

export type UpdateJob = z.infer<typeof UpdateJobSchema>;

export const CreateJobSchema = JobSchema.omit({
	id: true,
	createdAt: true,
	updatedAt: true,
	deletedAt: true,
}).extend({
	customerId: z.string().cuid().optional(),
}) satisfies z.ZodType<Prisma.JobCreateInput>;

export const UpdateJobsBatchSchema = z.object({
	jobIds: z.array(z.string().cuid()),
	newState: TaskStateSchema,
}) satisfies z.ZodType<{ jobIds: string[]; newState: string }>;

export type UpdateJobsBatch = z.infer<typeof UpdateJobsBatchSchema>;

export type CreateJob = z.infer<typeof CreateJobSchema>;

export const FindJobsQuerySchema = z.object({
	where: z.object({
		id: z.string().cuid().optional(),
		ownerId: z.string().cuid(),
		state: TaskStateSchema.optional(),
		beginDate: z
			.object({
				gte: z.coerce.date().optional(),
				lte: z.coerce.date().optional(),
			})
			.optional(),
		AND: z.any().optional(),
		OR: z.any().optional(),
	}),
	include: z
		.object({
			tasks: z
				.object({
					include: z
						.object({
							times: z.boolean().optional(),
						})
						.optional(),
				})
				.optional(),
		})
		.optional(),
	orderBy: z
		.object({
			identifier: z.enum(['asc', 'desc']).optional(),
			title: z.enum(['asc', 'desc']).optional(),
			employees: z.enum(['asc', 'desc']).optional(),
			companyName: z.enum(['asc', 'desc']).optional(),
			beginDate: z.enum(['asc', 'desc']).optional(),
			endDate: z.enum(['asc', 'desc']).optional(),
			state: z.enum(['asc', 'desc']).optional(),
			profitability: z.enum(['asc', 'desc']).optional(),
			time: z.enum(['asc', 'desc']).optional(),
			timeRatio: z.enum(['asc', 'desc']).optional(),
			estimatedTime: z.enum(['asc', 'desc']).optional(),
			realPrice: z.enum(['asc', 'desc']).optional(),
			balancePrice: z.enum(['asc', 'desc']).optional(),
			estimatedPrice: z.enum(['asc', 'desc']).optional(),
		})
		.optional(),
}) satisfies z.ZodType<Prisma.JobFindManyArgs>;

export const FindActiveJobsQuerySchema = z.object({
	where: z.object({
		id: z.string().cuid().optional(),
		state: TaskStateSchema.optional(),
	}),
}) satisfies z.ZodType<Prisma.JobFindManyArgs>;

export const GetActiveJobsParamsQuery = z.object({
	q: FindActiveJobsQuerySchema.optional(),
});

export type GetActiveJobsParams = z.infer<typeof GetActiveJobsParamsQuery>;

export const JobsFiltersSchema = z.object({
	identifier: z.string().optional(),
	title: z.string().optional(),
	companyName: z.string().optional(),
	beginDate: z.array(z.string().nullable()).optional(),
	endDate: z.array(z.string().nullable()).optional(),
	state: z.string().optional(),
	employees: z.string().optional(),
	profitability: z.array(z.number()).optional(),
	time: z.array(z.number()).optional(),
	timeRatio: z.array(z.number()).optional(),
	estimatedTime: z.array(z.number()).optional(),
	realPrice: z.array(z.number()).optional(),
	balancePrice: z.array(z.number()).optional(),
	estimatedPrice: z.array(z.number()).optional(),
	fullTextSearch: z.string().optional(),
	timeRange: z.array(z.string().nullable()).optional(),
});

export type JobsFilters = z.infer<typeof JobsFiltersSchema>;

export const GetJobsParamsQuery = z.object({
	id: z.string().cuid().optional(),
	state: TaskStateSchema.optional(),
	customerId: z.string().optional(),
	orderBy: z
		.object({
			identifier: z.enum(['asc', 'desc']).optional(),
			title: z.enum(['asc', 'desc']).optional(),
			employees: z.enum(['asc', 'desc']).optional(),
			companyName: z.enum(['asc', 'desc']).optional(),
			beginDate: z.enum(['asc', 'desc']).optional(),
			endDate: z.enum(['asc', 'desc']).optional(),
			state: z.enum(['asc', 'desc']).optional(),
			profitability: z.enum(['asc', 'desc']).optional(),
			time: z.enum(['asc', 'desc']).optional(),
			timeRatio: z.enum(['asc', 'desc']).optional(),
			estimatedTime: z.enum(['asc', 'desc']).optional(),
			realPrice: z.enum(['asc', 'desc']).optional(),
			balancePrice: z.enum(['asc', 'desc']).optional(),
			estimatedPrice: z.enum(['asc', 'desc']).optional(),
		})
		.optional(),
	filters: JobsFiltersSchema.optional(),
	pagination: z
		.object({
			pageSize: z.number(),
			page: z.number(),
		})
		.optional(),
	cursor: z.string().optional(),
});

export type GetJobsParams = z.infer<typeof GetJobsParamsQuery>;

export const GetJobEmployeesParamsQuery = z.object({
	ownerId: z.string().cuid(),
});

export type GetJobEmployeesParams = z.infer<typeof GetJobEmployeesParamsQuery>;

export const GetJobsWithTimesParamsQuery = z.object({
	q: FindJobsQuerySchema.optional(),
});

export type GetJobsWithTimesParams = z.infer<typeof GetJobsParamsQuery>;

export const GetJobsCostsParamsQuery = z.object({
	limit: z.coerce.string(),
	startDate: z
		.object({
			gte: z.coerce.date().optional(),
			lte: z.coerce.date().optional(),
		})
		.optional(),
});

export type GetJobsCostsParams = z.infer<typeof GetJobsCostsParamsQuery>;

export const GetJobsProfitabilityPercentageParamsQuery = z.object({
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
});

export type GetJobsProfitabilityPercentageParams = z.infer<typeof GetJobsProfitabilityPercentageParamsQuery>;

export const GetBestAndWorstJobsParamsQuery = z.object({
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
});

export type GetBestAndWorstJobsParams = z.infer<typeof GetBestAndWorstJobsParamsQuery>;

export const GetBestAndWorstJobsByProductivityParamsQuery = z.object({
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
});

export type GetBestAndWorstJobsByProductivityParams = z.infer<typeof GetBestAndWorstJobsByProductivityParamsQuery>;

export const CopyJobSchema = z.object({
	rowId: z.string().cuid(),
});

export type CopyJob = z.infer<typeof CopyJobSchema>;
