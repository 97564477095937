import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';
import { PersonTypeSchema } from './customer';

export const SettingsSchema = z.object({
	qrCodeRequestDisabled: z.boolean().optional(),
});

export const OwnerSchema = z.object({
	id: z.string().cuid(),
	personType: PersonTypeSchema.nullable(),
	companyName: z.string().nullable(),
	identificationNumber: z.string().nullable(),
	taxIdentificationNumber: z.string().nullable(),
	email: z.string().email().nullable(),
	phoneNumber: z.string().nullable(),
	firstName: z.string().nullable(),
	surName: z.string().nullable(),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date().nullable(),
	color: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
	stripeSubscriptionId: z.string().nullable(),
	stripeCustomerId: z.string().nullable(),
	trialEndDate: z.coerce.date().nullable(),
	employeesPaidFor: z.instanceof(PrismaTypes.Prisma.Decimal).nullable(),
	futureEmployeesPaidFor: z.instanceof(PrismaTypes.Prisma.Decimal).nullable(),
	subscriptionIsAutoRenew: z.boolean().nullable(),
	subscriptionPeriodEnd: z.coerce.date().nullable(),
	subscriptionPeriodStart: z.coerce.date().nullable(),
	subscriptionCancelledAt: z.coerce.date().nullable(),
	subscriptionActiveFrom: z.coerce.date().nullable(),
	settings: SettingsSchema.nullable(),
}) satisfies z.ZodType<PrismaTypes.Owner>;

export type Owner = z.infer<typeof OwnerSchema>;
