import { z } from 'zod';
import { Prisma } from '@prisma/client';
import { WorkScheduleSchema } from '../prisma/workSchedule';

export const UpdateWorkScheduleSchema = WorkScheduleSchema.omit({
	id: true,
	employeeId: true,
	coordinateId: true,
	deletedAt: true,
}).extend({
	date: z.string().nullish(),
	workScheduleType: z.string().nullish(),
	attendanceDuration: z.number().nullable(),
}) satisfies z.ZodType<Prisma.WorkScheduleUpdateInput>;

export type UpdateWorkSchedule = z.infer<typeof UpdateWorkScheduleSchema>;

export const UpdateWorkScheduleAbsenceSchema = WorkScheduleSchema.omit({
	id: true,
	qrcodeId: true,
	createdAt: true,
	updatedAt: true,
	requestId: true,
	coordinateId: true,
	deletedAt: true,
}).extend({
	newId: z.string().optional().nullable(),
	employeeId: z.string().optional().nullable(),
	workScheduleType: z.string().optional().nullish(),
	attendanceDuration: z.number().nullish(),
}) satisfies z.ZodType<Prisma.WorkScheduleUpdateInput>;

export type UpdateWorkScheduleAbsence = z.infer<typeof UpdateWorkScheduleAbsenceSchema>;

export const CreateWorkScheduleSchema = WorkScheduleSchema.omit({
	id: true,
	createdAt: true,
	updatedAt: true,
	coordinateId: true,
	deletedAt: true,
}) satisfies z.ZodType<Prisma.WorkScheduleCreateInput>;

export type CreateWorkSchedule = z.infer<typeof CreateWorkScheduleSchema>;

export const WorkScheduleWithRelationsSchema = WorkScheduleSchema.extend({
	qrcode: z
		.object({
			location: z.string(),
		})
		.nullable(),
	request: z
		.object({
			note: z.string(),
			state: z.string(),
		})
		.nullable(),
});

export type WorkScheduleWithRelations = z.infer<typeof WorkScheduleWithRelationsSchema>;

export const FindWorkScheduleQuerySchema = z.object({
	where: z
		.object({
			employeeId: z.string().cuid(),
			date: z.string().optional(),
			workScheduleType: z.string().optional(),
		})
		.optional(),
}) satisfies z.ZodType<Prisma.WorkScheduleFindManyArgs>;

export type FindWorkScheduleQuery = z.infer<typeof FindWorkScheduleQuerySchema>;

export const GetWorkSchedulesParamsQuery = z.object({
	q: FindWorkScheduleQuerySchema.optional(),
});

export type GetWorkSchedulesParams = z.infer<typeof GetWorkSchedulesParamsQuery>;

export const UpdateWorkSchedulesBatchSchema = z.object({
	newData: z.array(UpdateWorkScheduleAbsenceSchema),
	initialData: z.array(UpdateWorkScheduleAbsenceSchema),
}) satisfies z.ZodType<{ newData: UpdateWorkScheduleAbsence[] }>;

export type UpdateWorkSchedulesBatch = z.infer<typeof UpdateWorkSchedulesBatchSchema>;
