import { z } from 'zod';
import { TableSettingsSchema } from '../rest/tableSettings';

export const DashboardSettingsSchema = z.object({
	userRoleId: z.string().optional(),
	dashboardType: z.string().optional(),
});

export const UiSettingsSchema = z.object({
	employeeTable: TableSettingsSchema.optional(),
	jobTable: TableSettingsSchema.optional(),
	currentOverviewTable: TableSettingsSchema.optional(),
	monthlyReviewTable: TableSettingsSchema.optional(),
	customersTable: TableSettingsSchema.optional(),
	dashboard: DashboardSettingsSchema.optional(),
});
