import { z } from 'zod';
import * as PrismaTypes from '@prisma/client';
import { TaskStateSchema, TaskWithRelationsSchema } from './task';
import { createCursorPaginatedResponseSchema } from '../rest/common';

export const JobSchema = z.object({
	id: z.string().cuid(),
	title: z.string().nullable(),
	identifier: z.string().nullable(),
	description: z.string().nullable(),
	state: TaskStateSchema.nullable(),
	beginDate: z.coerce.date().nullable(),
	endDate: z.coerce.date().nullable(),
	createdAt: z.coerce.date().nullable(),
	updatedAt: z.coerce.date().nullable(),
	customerId: z.string().nullable(),
	ownerId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
}) satisfies z.ZodType<PrismaTypes.Job>;

export const JobSchemaTable = z.object({
	id: z.string().cuid(),
	title: z.string().nullable(),
	identifier: z.string().nullable(),
	description: z.string().nullable(),
	state: TaskStateSchema.nullable(),
	beginDate: z.coerce.date().nullable(),
	endDate: z.coerce.date().nullable(),
	employees: z
		.array(
			z.object({
				id: z.string().cuid(),
				employeeId: z.string().cuid(),
				firstName: z.string().nullable(),
				surName: z.string().nullable(),
				image: z.string().nullable(),
			}),
		)
		.nullable(),
	profitability: z.union([z.string(), z.number()]),
	time: z.number(),
	timeRatio: z.number(),
	estimatedTime: z.number(),
	realPrice: z.number(),
	balancePrice: z.number(),
	estimatedPrice: z.number(),
	createdAt: z.coerce.date().nullable(),
	updatedAt: z.coerce.date().nullable(),
	customerId: z.string().nullable(),
	companyName: z.string().nullable(),
	ownerId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
}) satisfies z.ZodType<PrismaTypes.Job>;

export const JobWithRelationsSchema = JobSchema.extend({
	customer: z
		.object({
			id: z.string().cuid(),
			companyName: z.string().nullable(),
		})
		.nullish(),
	tasks: z.array(TaskWithRelationsSchema).nullish(),
});

export const JobWithRelationsTableSchema = JobSchemaTable.extend({
	customer: z
		.object({
			id: z.string().cuid(),
			companyName: z.string().nullable(),
		})
		.nullish(),
	tasks: z.array(TaskWithRelationsSchema).nullish(),
});

export const JobWithRelationsPageSchema = z.object({
	times: z.array(JobWithRelationsSchema),
	records: z.array(JobWithRelationsSchema).nullish(),
	nextCursor: z.string(),
});

export type JobWithRelationsPage = z.infer<typeof JobWithRelationsPageSchema>;

export const JobCostsSchema = z.object({
	id: z.string(),
	title: z.string().nullable(),
	tasks: z.array(
		z.object({
			id: z.string(),
			times: z.array(
				z.object({
					id: z.string(),
					start: z.coerce.date(),
					end: z.coerce.date().nullable(),
					hourlyRate: z.instanceof(PrismaTypes.Prisma.Decimal).nullable(),
					employee: z
						.object({
							hourlyRate: z.instanceof(PrismaTypes.Prisma.Decimal).nullable(),
						})
						.nullable(),
				}),
			),
		}),
	),
});

export const JobWithCostsSchema = JobCostsSchema.extend({
	totalCosts: z.number(),
});

export const JobsWithCostsSchema = z.object({
	totalCosts: z.number(),
	jobs: z.array(JobWithCostsSchema),
});

export const ActiveJobSchema = z.object({
	title: z.string().nullish(),
	tasks: z.array(
		z.object({
			employee: z
				.object({
					id: z.string().cuid(),
				})
				.nullable(),
			times: z.array(
				z.object({
					start: z.coerce.date(),
					end: z.coerce.date().nullable(),
				}),
			),
		}),
	),
});

export const ActiveJobsSchema = z.object({
	activeJobsCount: z.number(),
	countEmployees: z.array(
		z.object({
			job: ActiveJobSchema,
			activeEmployees: z.number(),
		}),
	),
	inProgressJobsCount: z.number(),
});

export const TopJobWithProductivitySchema = z.object({
	id: z.string().cuid(),
	ownerId: z.string().nullable(),
	title: z.string().nullable(),
	identifier: z.string().nullable(),
	productivityPercentage: z.number(),
	logo: z.string().nullable(),
});

export const BestAndWorstJobsSchema = z.object({
	bestJobs: z.array(TopJobWithProductivitySchema),
	worstJobs: z.array(TopJobWithProductivitySchema),
});

export const JobsProfitabilityPercentageSchema = z.object({
	totalProfitabilityPercentage: z.number(),
	isGreen: z.boolean(),
});

export const JobIdSchema = z.object({
	id: z.string().cuid(),
});

export type Job = z.infer<typeof JobSchema>;
export type JobWithRelations = z.infer<typeof JobWithRelationsSchema>;
export type JobWithRelationsTable = z.infer<typeof JobWithRelationsTableSchema>;
export type JobCosts = z.infer<typeof JobCostsSchema>;
export type JobWithCosts = z.infer<typeof JobWithCostsSchema>;
export type JobsWithCosts = z.infer<typeof JobsWithCostsSchema>;
export type ActiveJob = z.infer<typeof ActiveJobSchema>;
export type JobId = z.infer<typeof JobIdSchema>;
export type ActiveJobs = z.infer<typeof ActiveJobsSchema>;
export type BestAndWorstJobs = z.infer<typeof BestAndWorstJobsSchema>;
export type TopJobWithProductivity = z.infer<typeof TopJobWithProductivitySchema>;
export type JobsProfitabilityPercentage = z.infer<typeof JobsProfitabilityPercentageSchema>;

export const GetJobWithRelationsPaginatedResponseSchema = createCursorPaginatedResponseSchema(JobWithRelationsSchema);
export type GetJobWithRelationsPaginatedResponse = z.infer<typeof GetJobWithRelationsPaginatedResponseSchema>;

export const GetJobEmployeesResponseSchema = z.object({
	uniqueEmployees: z.array(
		z.object({
			firstName: z.string().cuid(),
			surName: z.string().nullable(),
			id: z.string().nullable(),
		}),
	),
});
export type GetJobEmployeesResponse = z.infer<typeof GetJobEmployeesResponseSchema>;
