import { z } from 'zod';

export const TableSettingsSchema = z.object({
	sorting: z.any().optional(),
	globalFilter: z.string().optional(),
	columnFilters: z.any().optional(),
	customData: z.any().optional(),
	userRoleId: z.string().optional(),
	ownerId: z.string().optional(),
	tableName: z.string().optional(),
});

export type TableSettings = z.infer<typeof TableSettingsSchema>;
