import { z } from 'zod';

export const TimeReportingParamsSchema = z.object({
	employeeId: z.string().cuid(),
	taskId: z.string().cuid().nullable().optional(),
	action: z.enum(['start', 'end']).optional(), // Akce: začátek nebo konec pracovní doby
	qrCodeId: z.string().cuid().optional(),
	reqId: z.string().cuid().optional(),
	stop: z.boolean().optional(),
	location: z
		.object({
			latitude: z.number().min(-90).max(90), // Zeměpisná šířka
			longitude: z.number().min(-180).max(180), // Zeměpisná délka
			accuracy: z.number().min(0).optional(), // Přesnost polohy (volitelné)
			speed: z.number().min(0).optional(), // Rychlost (volitelné)
		})
		.optional(),
});

export type TimeReportingParams = z.infer<typeof TimeReportingParamsSchema>;
