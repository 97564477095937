import type { NextRequest } from 'next/server';
import { z } from 'zod';

export * from './prisma/address';
export * from './prisma/attachment';
export * from './prisma/bankAccount';
export * from './prisma/comment';
export * from './prisma/contactPerson';
export * from './prisma/customer';
export * from './prisma/coordinate';
export * from './prisma/employee';
export * from './prisma/job';
export * from './prisma/notification';
export * from './prisma/groupedNotification';
export * from './prisma/owner';
export * from './prisma/task';
export * from './prisma/time';
export * from './prisma/user';
export * from './prisma/userRole';
export * from './prisma/uiSettings';
export * from './prisma/workSchedule';
export * from './prisma/qrCode';
export * from './prisma/request';
export * from './prisma/invitation';
export * from './prisma/tempNativeLog';

export * from './rest/common';
export * from './rest/addresses';
export * from './rest/comments';
export * from './rest/bankAccount';
export * from './rest/customers';
export * from './rest/coordinate';
export * from './rest/employees';
export * from './rest/jobs';
export * from './rest/notifications';
export * from './rest/owners';
export * from './rest/tasks';
export * from './rest/times';
export * from './rest/users';
export * from './rest/auth';
export * from './rest/userRoles';
export * from './rest/uiSettings';
export * from './rest/tableSettings';
export * from './rest/qr';
export * from './rest/requests';
export * from './rest/workSchedules';
export * from './rest/invitations';
export * from './rest/tempNativeLog';
export * from './rest/ares';
export * from './rest/contactPerson';
export * from './rest/sendEmail';
export * from './rest/search';
export * from './rest/avatar';
export * from './rest/whisperAddress';
export * from './rest/stripe';
export * from './rest/subscription';
export * from './rest/timeReporting';

// tohle je trochu krkolomne naschval kvuli tomu explicitnimu vraceni typu kvuli generovani openapi
export async function parseBody<A, T extends z.ZodTypeAny = z.ZodTypeAny>(schema: T, request: NextRequest): Promise<A> {
	const json = await request.json();
	console.debug('received request body', json);
	return schema.parse(json);
}

export async function parseFormData<A, T extends z.ZodTypeAny = z.ZodTypeAny>(
	schema: T,
	formData: FormData,
): Promise<A> {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const json = Object.fromEntries(formData);
	console.debug('received request body', json);
	return schema.parse(json);
}

export async function parseBodyFormData<A, T extends z.ZodTypeAny = z.ZodTypeAny>(
	schema: T,
	request: NextRequest,
): Promise<A> {
	const formData = await request.formData();
	return parseFormData(schema, formData);
}

export async function parseQueryObject<A, T extends z.ZodTypeAny = z.ZodTypeAny>(
	schema: T,
	request: NextRequest,
): Promise<A> {
	const query = {} as Record<string, unknown>;
	request.nextUrl.searchParams.forEach((value, key) => {
		if (/^\d+$/.test(value)) {
			query[key] = value;
		} else {
			try {
				query[key] = JSON.parse(value);
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
			} catch (e) {
				query[key] = value;
			}
		}
	});
	console.debug('received query', JSON.stringify(query, null, 2));
	return schema.parse(query);
}
