import { z } from 'zod';
import type { Prisma } from '@prisma/client';

import { anyPass, isEmpty, isNil } from 'ramda';
import { CustomerSchema } from '../prisma/customer';
import { CreateBankAccountSchema } from './bankAccount';
import { ContactPersonSchema } from '../prisma/contactPerson';
import { CreateAddressSchema } from './addresses';

const isNilOrEmpty = anyPass([isNil, isEmpty]);

export const UpdateCustomerSchema = CustomerSchema.omit({
	id: true,
	ownerId: true,
	deletedAt: true,
})
	.refine(
		(data) => {
			if (data.personType === 'legalEntity') {
				return !isNilOrEmpty(data.companyName);
			}
			return true;
		},
		{
			params: {
				i18n: 'companyNameRequired',
			},
			path: ['companyName'],
		},
	)
	.refine(
		(data) => {
			if (data.personType === 'legalEntity') {
				return !isNilOrEmpty(data.identificationNumber);
			}
			return true;
		},
		{
			params: {
				i18n: {
					key: 'identificationNumberLegalEntityRequired',
					values: { minimum: 1 },
				},
			},
			path: ['identificationNumber'],
		},
	) satisfies z.ZodType<Prisma.CustomerUpdateInput>;

export type UpdateCustomer = z.infer<typeof UpdateCustomerSchema>;

export const CreateCustomerSchema = CustomerSchema.omit({
	id: true,
	createdAt: true,
	updatedAt: true,
	deletedAt: true,
})
	.extend({
		bankAccountsToCreate: z.array(CreateBankAccountSchema).optional(),
		contactPersonsToCreate: z.array(ContactPersonSchema).optional(),
		addressesToCreate: z.array(CreateAddressSchema).optional(),
	})
	.refine(
		(data) => {
			if (data.personType === 'legalEntity') {
				return !isNilOrEmpty(data.companyName);
			}
			return true;
		},
		{
			params: {
				i18n: 'companyNameRequired',
			},
			path: ['companyName'],
		},
	)
	.refine(
		(data) => {
			if (data.personType === 'legalEntity') {
				return !isNilOrEmpty(data.identificationNumber);
			}
			return true;
		},
		{
			params: {
				i18n: {
					key: 'identificationNumberLegalEntityRequired',
					values: { minimum: 1 },
				},
			},
			path: ['identificationNumber'],
		},
	) satisfies z.ZodType<Prisma.CustomerCreateInput>;

export type CreateCustomer = z.infer<typeof CreateCustomerSchema>;

export const FindCustomerQuerySchema = z.object({
	where: z.object({
		id: z.string().cuid().optional(),
	}),
}) satisfies z.ZodType<Prisma.CustomerFindManyArgs>;

export type FindCustomerQuery = z.infer<typeof FindCustomerQuerySchema>;

export const GetCustomersParamsQuery = z.object({
	q: FindCustomerQuerySchema.optional(),
});
export type GetCustomersParams = z.infer<typeof GetCustomersParamsQuery>;
